@import './variables.css';

/* fix height of element */
[data-reactroot] {
  height: 100%;
}

.graphql-voyager {
  font: var(--base-font-size) var(--base-font-family);
  display: flex;
  height: 100%;

  @media (--small-viewport) {
    flex-direction: column;
  }

  & > .doc-panel {
    width: var(--doc-panel-width);
    min-width: var(--doc-panel-width);
    background: var(--doc-panel-bg-color);
    box-sizing: border-box;
    position: relative;
    z-index: 10;
  }

  & > .viewport {
    flex: 1;
    max-height: 100vh;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  @media (--small-viewport) {
    & > .doc-panel,
    & > .viewport {
      height: 50%;
      width: 100%;
      max-width: none;
    }
  }

  & > .menu-content {
    position: absolute;
    bottom: var(--panel-spacing);
    left: calc(var(--doc-panel-width) + var(--panel-spacing));
    background: var(--doc-panel-bg-color);
    box-shadow: 0 4px 4px -2px var(--shadow-color);
    border: 1px solid var(--shadow-color);
    padding: var(--spacing-unit);

    & > .setting-other-options {
      display: flex;

      & > label:first-of-type {
        padding-right: var(--spacing-unit);
      }

      & > label {
        margin-bottom: calc(var(--panel-spacing) - var(--spacing-unit));
      }
    }

    @media (--small-viewport) {
      left: 10px;
    }
  }
}
