@import '../variables.css';

.loading-box {
  position: absolute;
  left: 320px;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;

  align-items: center;
  justify-content: center;

  display: flex;
  color: white;

  @media (--small-viewport) {
    left: 0;
    top: 50%;
  }
}

.loading-animation {
  width: 180px;
  & path {
    fill: var(--secondary-color);
  }

  & h1 {
    text-align: center;
    color: var(--secondary-color);
    letter-spacing: 4px;
  }

  @media (--small-viewport) {
    text-align: center;

    & svg {
      max-width: 40%;
    }

    & h1 {
      font-size: 1.5em;
    }
  }
}
