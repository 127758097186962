@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import "@aws-amplify/ui-react/styles.css";

@layer base {
  html {
    font-family: 'Inter', sans-serif;
  }
}

/* Amplify Auth UI */
.amplify-button[data-variation='primary'] {
  background: #2D5B6C;
}

:root {
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);

  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
}

/* Team shifts */
.card,
.meeting,
.review,
.shift {
  background: #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 2px 4px #00000040;
  margin-bottom: 1em;
  overflow: auto;
  padding: 1em 0.85em 0.85em;
  position: relative;
}

.shift {
  padding: 0 0 6em;
  position: relative;
}

@media screen and (max-width: 1400px) {
  .card,
  .period,
  .shift {
    box-sizing: border-box;
    margin-left: .5%;
    margin-right: .5%;
    width: 23%;
  }
}

@media screen and (min-width: 1401px) {
  .card,
  .period,
  .shift {
    box-sizing: border-box;
    margin-left: 0;
    margin-right: 1%;
    width: 19%;
  }

  .content .shift {
    width: 24%;
  }
}

@media screen and (max-width: 1200px) {
  .card,
  .period,
  .shift {
    box-sizing: border-box;
    margin-left: .5%;
    margin-right: .5%;
    width: 31%;
  }
}

@media screen and (max-width: 900px) {

  .card,
  .period,
  .shift {
    box-sizing: border-box;
    margin-left: .5%;
    margin-right: .5%;
    width: 48%;
  }
}

@media screen and (max-width: 600px) {

  .card,
  .period,
  .shift {
    display: block;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

.shift .free,
.shift .visit {
  margin: 0.85em;
  padding: 0.85em;
}

.free,
.visit {
  border-radius: 4px;
  margin: 0.85em 0;
}

.whitebg {
  background: #fff;
}

.free div,
.visit div {
  padding: 0.85em;
  position: relative;
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
}

.shift .support {
  bottom: 0;
  position: absolute;
}

@media screen and (max-width: 1400px) {
  .support {
    background: #fff;
    border-radius: 0 0 4px 4px;
    border-top: 1px solid #bfbfbf;
    box-sizing: border-box;
    margin-top: 1em;
    padding: 0.85em 1em;
    width: 100%;
  }
}

.subCard,
.support {
  background: #fff;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #bfbfbf;
  box-sizing: border-box;
  padding: 0.85em 1em;
  width: 100%;
}

.card h4,
.card p,
.meeting h4,
.meeting p,
.shift h4,
.shift p {
  color: #185d6f;
}

.visit .colleague {
  bottom: -0.1em;
  height: 30px;
  position: absolute;
  right: 0.5em;
  width: 30px;
}

.colleague img {
  border: 1px solid #333;
  border-radius: 50%;
  margin: 0.25em;
  max-height: 80px;
  max-width: 80px;
  min-height: 30px;
  min-width: auto;
}

.visit .colleague img {
  width: 30px;
}

.wrap {
  flex-wrap: wrap;
}

.shift.close .free,
.shift.close .info .full,
.shift.close .visit,
.shift.close p.travel,
.shift.open .info .short {
  display: none;
}

.cancelledVisit {
  background-color: rgba(165, 42, 42, 0.3)
}

.movedVisit {
  background-color: rgba(113, 18, 255, 0.28)
}

.dropdown:hover .dropdown-menu {
  display: block;
}
